<template>
  <div class="cont">
    <NavBar></NavBar>
    <!-- 轮播图 -->
    <b-carousel
      id="carouselExampleControls"
      :interval="4000"
      controls
      background="#ababab"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="item in banners"
        v-bind:key="item.banner_path"
        :img-src="item.banner_path"
        @click.native="goPack(item.url)"
      >
      </b-carousel-slide>
      <div class="search-box">
        <div class="search-input-box">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle search-select"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {{ selectInfo.title }}
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                class="dropdown-item"
                href="#"
                v-for="(item, index) in selectInfoList"
                :key="index"
                @click="selectInfo = item"
                >{{ item.title }}</a
              >
            </div>
          </div>
          <div class="search-split-box">
            <img
              src="../../../public/icon/search-split@2x.png"
              class="search-split"
            />
          </div>
          <input
            v-model="keyWords"
            class="search-input-info"
            :placeholder="searchPlaceholder"
            @keyup.enter="goSearch"
          />

          <div class="search-split-box margin-left-auto">
            <img
              src="../../../public/icon/product-distingush@2x.png"
              class="search-icon"
            />
          </div>
          <div class="search-split-box">
            <img
              src="../../../public/icon/search-split@2x.png"
              class="search-split"
            />
          </div>
          <div class="search-split-box margin-right-22" @click="goSearch">
            <img
              src="../../../public/icon/search-icon@2x.png"
              class="search-icon"
            />
          </div>
        </div>
        <div class="key-words-box">
          <span class="key-words-title"
            >{{ $t("homeSearchPlaceholder") }}:</span
          >
          <span
            class="key-words"
            v-for="(item, index) in keyWordsList"
            :key="index"
            >{{ item }}</span
          >
        </div>
      </div>
    </b-carousel>
    <!-- 
		<Slides :list="slideList"></Slides> -->
    <div class="style">
      <b-row class="style-cont">
        <b-col
          sm="3"
          md="2"
          lg="2"
          xl="1"
          class="style-item"
          v-for="(item, index) in styleLists"
          :key="index"
          @click="goStyle(item)"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </b-col>
      </b-row>
    </div>



    <div class="a1">
      <div class="b0">自助搭配，无限应用到家</div>
      <div class="b1">
        <div class="c0 jiant" @click="sectVr('left')">
          <img src="../../../public/icon/a52.png" alt="" />
        </div>
        <b-row class="c1">
          <b-col
            class="d0"
            sm="6"
            md="6"
            lg="6"
            xl="3"
            v-for="(item, index) in vrList"
            :key="index"
            @click="goVr(item.three_d_url)"
          >
            <div class="e0">
              <img class="f0" :src="item.img_path" alt="" />
              <span class="f1">{{ item.works_name }}</span>
              <span class="f2"
                >{{ item.hourse_type }} | {{ item.measure_area }}</span
              >
            </div>
          </b-col>
        </b-row>
        <div class="c2 jiant" @click="sectVr('right')">
          <img src="../../../public/icon/a53.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 配置 -->
    <div class="container-index">
      <div class="choice-title">一键购买，精选主推套餐</div>
      <b-row class="choice-box">
        <b-col
          class="choice-big"
          sm="12"
          md="12"
          lg="12"
          xl="8"
          @click="goPackage(taocanList.topCont.setmeal_id)"
        >
          <img class="choice-big-img" :src="taocanList.topCont.lroom_img" />
        </b-col>
        <b-col
          class="choice-min"
          sm="6"
          md="6"
          lg="6"
          xl="4"
          v-for="(item, index) in taocanList.list"
          :key="index"
          @click="goPackage(item.setmeal_id)"
        >
          <img class="choice-min-img" :src="item.lroom_img" />
        </b-col>
      </b-row>
      <div class="more_box" @click="goMorePackage">查看更多套餐</div>

      <div class="selection_box">
        <div class="selection_title">臻选家具，私享生活品质</div>
        <b-row class="selection">
          <b-col
            class="title_list"
            :class="item.id == chooesStyleed ? 'style_bot' : ''"
            sm="3"
            md="3"
            lg="3"
            xl="1"
            @click="chooseStyle(item.id)"
            v-for="(item, index) in styleType"
            :key="index"
            >{{ item.name }}</b-col
          >
          <b-col
            class="title_list title_rig"
            @click="goMoreGoods"
            sm="12"
            md="12"
            lg="12"
            xl="8"
            >客厅 | 餐厅 | 卧室 | 书房 | 儿童房 | 查看更多</b-col
          >
        </b-row>
        <b-row class="choice-box">
          <b-col
            class="choice-big"
            sm="12"
            md="12"
            lg="12"
            xl="8"
            @click="goGoodsDetails(styleGoods.topCont.goods_id)"
          >
            <img class="choice-big-img" :src="styleGoods.topCont.img_path" />
            <div class="choice_tit">
              <span class="choice_name">{{
                styleGoods.topCont.goods_name
              }}</span>
              <span class="choice_price"
                >￥{{ styleGoods.topCont.sale_price }}</span
              >
            </div>
          </b-col>
          <b-col
            class="choice-min-p"
            sm="6"
            md="6"
            lg="6"
            xl="4"
            @click="goGoodsDetails(item.goods_id)"
            v-for="(item, index) in styleGoods.list"
            :key="index"
          >
            <img class="choice-min-img" :src="item.img_path" />
            <div class="choice_title">
              <span class="choice_name">{{ item.goods_name }}</span>
              <span class="choice_price">￥{{ item.sale_price }}</span>
            </div>
          </b-col>
        </b-row>
      </div>

      <div></div>

      <!--  3d模型 end> -->
    </div>
    <div class="bg_img">
      <div class="future-title">装点未来，你的生活方式</div>
      <b-row class="future-cont">
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="decorative" @click="goProductList('21', '419', '468')">
            <div class="decorative-icon">
              <span>装饰摆件</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="flower" @click="goProductList('21', '36', '')">
            <div class="flower-icon">
              <span>墙饰画艺</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
          <div class="floral" @click="goProductList('21', '419', '469')">
            <div class="floral-icon">
              <span>花艺花器</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="lamps" @click="goProductList('24', '', '')">
            <div class="lamps-icon">
              <span>创意灯具</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="carpet" @click="goProductList('21', '35', '')">
            <div class="carpet-icon">
              <span>地毯挂毯</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
          <div class="sculpture" @click="goProductList('21', '419', '474')">
            <div class="sculpture-icon">
              <span>艺术雕塑</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="curtain" @click="goProductList('4', '26', '')">
            <div class="curtain-icon">
              <span>窗帘布艺</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
          <div class="textile" @click="goProductList('4', '34', '')">
            <div class="textile-icon">
              <span>家纺</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="2" class="future-item">
          <div class="daily" @click="goProductList('21', '37', '')">
            <div class="daily-icon">
              <span>生活日用</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
          <div class="other" @click="goProductList('21', '419', '')">
            <div class="other-icon">
              <span>其他</span>
              <div class="the-icon">
                <img src="../../../public/icon/The-white.png" alt="" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="future_more">
        <span @click="moreDesigner">查看更多软装</span>
      </div>
    </div>
    <!-- <div class="big_event">
      <div class="big_title">你好，一切从你开始</div>
      <div class="big_name">
        <span>胜捷美家</span>
      </div>
      <b-row class="big_cont">
        <b-col sm="12" md="12" lg="6" xl="6" class="big_left">
          <span>150+合作项目，10000+合作商</span>
          <img src="../../../public/icon/a3.png" alt="" />
        </b-col>
        <b-col sm="12" md="12" lg="6" xl="6" class="big_right">
          <p>
            公司总部位于哈尔滨，在海南、云南、广西地区具有多年房地产代理及渠道整合服务经验，是一家从事房地产营销代理、渠道专业整合的大型地产品牌服务商。2017年进驻海南市场，并成立海南分公司、三亚、东方分公司，深度合作项目150+，涵盖碧桂园、万科、保利等一线品牌，合作期间销售业绩斐然，获得行业内的高度认可。
          </p>
          <p>
            在地产行业精耕细作多年，有丰富的拎包入住经验，借助于地产营销代理、渠道专业整合的先导优势，链接和整合全球家居软装供应链资源，构建了完整的地产+家居产业链。
          </p>
          <p>
            公司整合行业全产业链条，打通从新房批量家装、交付定制装修、二手房翻新等全周期服务，致力于为用户打造“一站式置家”消费服务体验。团队核心力量深耕海南地产多年，立足海南本土，谙熟海南地产行业特性，用最专业的经验为用户提供美学场景立体化解决方案和服务，实现地产+家居行业资源合纵连横。
          </p>
        </b-col>
      </b-row>
    </div> -->
    <Right></Right>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
// import Slides from "@/components/Slides.vue"
import Right from "@/components/Right.vue";
import Footer from "@/components/Footer.vue";
import dump from "../../util/dump.js";
export default {
  components: {
    NavBar,
    // Slides,
    Right,
    Footer,
  },
  data() {
    return {
      bigBg:
        "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/37700da2-f715-43f7-b39e-f554c0487c90.jpg",
      colCount: 5,
      selectInfo: {
        title: this.$t("homeTwoSolution"),
        id: 1,
      },
      selectInfoList: [
        {
          title: this.$t("homeTwoSolution"),
          id: 1,
        },
        {
          title: this.$t("homeThreeSolution"),
          id: 2,
        },
        {
          title: this.$t("homeProduct"),
          id: 3,
        },
      ],
      keyWordsList: ["现代客厅", "卧室", "极简"],
      banners: [
        "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/37700da2-f715-43f7-b39e-f554c0487c90.jpg",
        "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/342383bc-315e-4a12-a934-ca58f50706cc.jpg",
        "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/c7dbfdee-1315-4848-90a4-88b8dfebdf1e.jpg",
      ],
      keyWords: "",
      searchPlaceholder: this.$t("homeSearchInputPlaceholder"),
      slideList: [
        {
          title: "SKU模型",
          sourceType: 2,
          src: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2cc15167-2b22-40db-bf3d-4dee5d36bc02.png",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2cc15167-2b22-40db-bf3d-4dee5d36bc02.png",
          link: "",
        },
        {
          title: "如何玩转胜捷美家",
          type: 1,
          sourceType: 1,
          src: "https://ugcydzd.qq.com/uwMROfz2r5xgoaQXGdGnC2dfJ7wDTjA2ZaADpOX3S6glXuYs/z3029gitnp2.p712.1.mp4?sdtfrom=v1010&guid=272b2f56a34ff86ebb91c1a013634fdb&vkey=560426C4262BBC071A4106A29A20C981EEEB6CA43C16C458D18DA46F97EAF9F94AD660C4978A253675D671846157E461F9B2EA080A2E89730B5E1D4010162FDF786A4E790E2C07C3DAA0AE9EBA090F27542DBA1805202A006E86EDD3F921A903AA8DEE2571BE7754D932AFFB1230D2BD9CF7ECC7DD39352BD8E8D4CDDE5EEECD3BBCF26118332A22",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0deeafed-a630-4882-9aca-aa44651abfa5.png",
          link: "",
        },
        {
          title: "二维美学场景",
          sourceType: 2,
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2d407e8d-9dd5-43e0-a5af-7f712a950146.png",
          src: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2d407e8d-9dd5-43e0-a5af-7f712a950146.png",
          link: "",
        },
        {
          title: "vip",
          sourceType: 2,
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/56619b98-3b5e-4eef-86fe-0a3eaab11adf.png",
          src: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/56619b98-3b5e-4eef-86fe-0a3eaab11adf.png",
          link: "",
        },
        {
          title: "装点商城",
          sourceType: 2,
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5b41adc7-992b-4cd2-a5a0-b67bf828b203.png",
          src: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5b41adc7-992b-4cd2-a5a0-b67bf828b203.png",
          link: "",
        },
      ],
      videos: [
        {
          srcUrl:
            "https://ugcydzd.qq.com/uwMROfz2r5xgoaQXGdGnC2dfJ7wDTjA2ZaADpOX3S6glXuYs/z3029gitnp2.p712.1.mp4?sdtfrom=v1010&guid=272b2f56a34ff86ebb91c1a013634fdb&vkey=560426C4262BBC071A4106A29A20C981EEEB6CA43C16C458D18DA46F97EAF9F94AD660C4978A253675D671846157E461F9B2EA080A2E89730B5E1D4010162FDF786A4E790E2C07C3DAA0AE9EBA090F27542DBA1805202A006E86EDD3F921A903AA8DEE2571BE7754D932AFFB1230D2BD9CF7ECC7DD39352BD8E8D4CDDE5EEECD3BBCF26118332A22",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/003196e3-68b5-48bc-90f4-c25d301a28bb.png",
        },
        {
          srcUrl:
            "https://ugcydzd.qq.com/uwMROfz2r5xgoaQXGdGnC2dfJ7wDTjA2ZaADpOX3S6glXuYs/z3029gitnp2.p712.1.mp4?sdtfrom=v1010&guid=272b2f56a34ff86ebb91c1a013634fdb&vkey=560426C4262BBC071A4106A29A20C981EEEB6CA43C16C458D18DA46F97EAF9F94AD660C4978A253675D671846157E461F9B2EA080A2E89730B5E1D4010162FDF786A4E790E2C07C3DAA0AE9EBA090F27542DBA1805202A006E86EDD3F921A903AA8DEE2571BE7754D932AFFB1230D2BD9CF7ECC7DD39352BD8E8D4CDDE5EEECD3BBCF26118332A22",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2add2b7d-5cac-4e68-abb3-960ace7f1268.png",
        },
        {
          srcUrl:
            "https://ugcydzd.qq.com/uwMROfz2r5xgoaQXGdGnC2dfJ7wDTjA2ZaADpOX3S6glXuYs/z3029gitnp2.p712.1.mp4?sdtfrom=v1010&guid=272b2f56a34ff86ebb91c1a013634fdb&vkey=560426C4262BBC071A4106A29A20C981EEEB6CA43C16C458D18DA46F97EAF9F94AD660C4978A253675D671846157E461F9B2EA080A2E89730B5E1D4010162FDF786A4E790E2C07C3DAA0AE9EBA090F27542DBA1805202A006E86EDD3F921A903AA8DEE2571BE7754D932AFFB1230D2BD9CF7ECC7DD39352BD8E8D4CDDE5EEECD3BBCF26118332A22",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/806d29ad-bffd-4582-9700-ea5c97794016.png",
        },
        {
          srcUrl:
            "https://ugcydzd.qq.com/uwMROfz2r5xgoaQXGdGnC2dfJ7wDTjA2ZaADpOX3S6glXuYs/z3029gitnp2.p712.1.mp4?sdtfrom=v1010&guid=272b2f56a34ff86ebb91c1a013634fdb&vkey=560426C4262BBC071A4106A29A20C981EEEB6CA43C16C458D18DA46F97EAF9F94AD660C4978A253675D671846157E461F9B2EA080A2E89730B5E1D4010162FDF786A4E790E2C07C3DAA0AE9EBA090F27542DBA1805202A006E86EDD3F921A903AA8DEE2571BE7754D932AFFB1230D2BD9CF7ECC7DD39352BD8E8D4CDDE5EEECD3BBCF26118332A22",
          poster:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5e147793-3b91-496a-99c5-95cc5ea42dd2.png",
        },
      ],
      designers: [
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 0,
          designNumber: 84,
        },
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 0,
          designNumber: 254,
        },
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 0,
          designNumber: 4,
        },
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 0,
          designNumber: 70,
        },
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 1,
          designNumber: 20,
        },
        {
          headImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
          nickName: "螺旋飞天猪",
          mySignature: "一只会飞天的猪",
          followType: 1,
          designNumber: 124,
        },
      ],
      labels: [
        {
          labelName: "全部",
          labelId: "",
        },
        {
          labelName: "极简",
          labelId: "",
        },
        {
          labelName: "轻奢",
          labelId: "",
        },
      ],
      solutionList: [
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/a2a251a4-0041-4e7f-997d-354882b92613.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
        {
          coverUri:
            "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
          name: "静享“家”的温暖",
          designerName: "许淇栋（形与品设计）",
          watchNumber: 124,
          designerHeagImgUrl:
            "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png",
        },
      ],
      styleLists: [
        {
          icon: require("../../../public/icon/a9.png"),
          name: "极简",
          id: "16",
        },
        {
          icon: require("../../../public/icon/a12.png"),
          name: "轻奢",
          id: "15",
        },
        {
          icon: require("../../../public/icon/a24.png"),
          name: "中式",
          id: "53",
        },
        {
          icon: require("../../../public/icon/a66.png"),
          name: "美式",
          id: "52",
        },
        {
          icon: require("../../../public/icon/a1.png"),
          name: "北欧/现代",
          id: "54",
        },
        {
          icon: require("../../../public/icon/a69.png"),
          name: "英式",
          id: "55",
        },
        {
          icon: require("../../../public/icon/a67.png"),
          name: "欧式/新古典",
          id: "56",
        },
        {
          icon: require("../../../public/icon/a63.png"),
          name: "床垫",
          id: "643",
        },
        {
          icon: require("../../../public/icon/a65.png"),
          name: "户外",
          id: "644",
        },
        {
          icon: require("../../../public/icon/a64.png"),
          name: "儿童",
          id: "645",
        },
      ],
      buildingList: [],
      vrList: [],
      vrListNew: [],
      vrIndex: 0,
      taocanList: {
        topCont: "",
        list: [],
      },
      styleType: [
        {
          name: "极简",
          id: "2",
        },
        {
          name: "轻奢",
          id: "1",
        },
        {
          name: "新中式",
          id: "3",
        },
        {
          name: "美式",
          id: "4",
        },
      ],
      chooesStyleed: "2",
      styleGoods: {
        topCont: "",
        list: [],
      },
      futureList: [],
      userInfo: "",
    };
  },
  created() {
    let width = document.body.clientWidth;
    if (width <= 800 && width > 400) {
      this.colCount = 2;
    } else if (width <= 400) {
      this.colCount = 1;
    } else if (width <= 1024 && width > 800) {
      this.colCount = 2;
    }
    this.getBanners();
    // //获取设计师
    // this.getDesingers();
    // //获取二维方案
    // this.getMasterpieces();
    // //获取三维方案
    // this.getDesign3D();
    //获取楼盘方案
    this.getBuildingList();
    //获取720方案
    this.getVrData();
    //获取套餐
    this.getTaocanData();
    //获取风格商品
    this.getStyleGoodsData();
    //获取装点未来
    this.getFutureData();
  },
  methods: {
    goPack(url) {
      console.log('123')
      let data = {
        type: 3,
        link: url,
      };
      dump.link(data);
    },
    goProductList(categoryId, categoryTwoId, categoryThreeId) {
      const url = this.$router.resolve({
        path: "/productList",
        query: {
          categoryId: categoryId,
          categoryTwoId: categoryTwoId,
          categoryThreeId: categoryThreeId,
        },
      });
      window.open(url.href,'_blank')
    },
    goPackage(id) {
      // window.open("taocan.html?setmeal_id=" + id);
      const url = this.$router.resolve({path:'/packageDetails',query:{setmeal_id:id}})
      window.open(url.href,'_blank')
    },
    goMorePackage() {
      window.open("index.html#/packageList");
    },
    moreDesigner() {
      const url = this.$router.resolve({ path: "/productList", query: { categoryId: "24" } });
      window.open(url.href,"_blank")
    },
    sectVr(type) {
      const that = this;
      if (type == "right") {
        if (that.vrIndex + 8 > that.vrListNew.length - 1) {
          that.$message({
            message: "已经到头啦",
            type: "warning",
          });
        } else {
          that.vrIndex += 4;
          that.vrList = that.vrListNew.slice(that.vrIndex, that.vrIndex + 4);
        }
      } else {
        if (that.vrIndex - 4 < 0) {
          that.$message({
            message: "已经到头啦",
            type: "warning",
          });
        } else {
          that.vrIndex -= 4;
          that.vrList = that.vrListNew.slice(that.vrIndex, that.vrIndex + 4);
        }
      }
    },
    goSearch() {
      const that = this;
      if (that.selectInfo.title == "搜项目") {
        that.$router.push({
          path: "/buildingPlan",
          query: { keyWords: that.keyWords },
        });
      } else if (that.selectInfo.title == "搜单品") {
        that.$router.push({
          path: "/productList",
          query: { keyWords: that.keyWords },
        });
      } else {
        // location.href = "index.html#/packageList?keyWords=" + that.keyWords; 
       location.href = "packageList?keyWords=" + that.keyWords;
      }
    },
    goGoodsDetails(id) {
      let data = {
        type: 2,
        // link: "index.html#/details?good_id=" + id,
         link: "#/details?good_id=" + id,
      };
      dump.link(data);
    },
    goMoreGoods() {
      this.$router.push({ path: "/productList", query: { categoryId: "2" } });
    },
    chooseStyle(id) {
      this.chooesStyleed = id;
      this.getStyleGoodsData();
    },
    goVr(url) {
      window.open(url);
    },
    goStyle(item) {
      const url = this.$router.resolve({
        path: "/productList",
        query: { categoryTwoId: item.id },
      });
      window.open(url.href,"_blank")
    },

    getFutureData() {
      const that = this;
      that.$api.getFuture({ goods_type: 2 }).then((res) => {
        that.futureList = res.data.goods.splice(0, 10);
        console.log("getFuture", res);
      });
    },
    getStyleGoodsData() {
      const that = this;
      that.$api
        .getStyleGoods({ style_type: that.chooesStyleed })
        .then((res) => {
          that.styleGoods.topCont = res.data[0];
          that.styleGoods.list = res.data.splice(1, 4);
        });
    },
    getTaocanData() {
      const that = this;
      that.$api.getTaocan({}).then((res) => {
        that.taocanList.topCont = res.data.setmeals[0];
        that.taocanList.list = res.data.setmeals.splice(1, 4);
      });
    },
    getVrData() {
      const that = this;
      that.$api.getVr({}).then((res) => {
        that.vrList = res.data.designers.splice(0, 4);
        this.vrListNew = res.data.designers;
        console.log(res);
      });
    },
    getBuildingList() {
      const that = this;
      that.$api.buildingList({}).then((res) => {
        that.buildingList = res.data.list.splice(0, 4);
      });
    },
    getDesign3D() {
      const that = this;
      that.$api.design3D({}).then((res) => {
        console.log(res.data.designers);
      });
    },
    getMasterpieces() {
      const that = this;
      that.$api
        .designMasterpieces({
          pageIndex: 1,
          pageSize: 6,
        })
        .then((res) => {
          console.log(res);
        });
    },
    getDesingers() {
      const that = this;
      that.$api
        .designers({
          pageIndex: 1,
          pageSize: 6,
        })
        .then((res) => {
          console.log(res);
        });
    },
    getMeal() {},
    getBanners() {
      const that = this;
      that.$api
        .banners({
          banner_type: 1,
        })
        .then((res) => {
          that.banners = res.data;
        });
    },
    // changeLanguage(lang) {
    // 	// 保存语言配置到localStorage
    // 	localStorage.setItem("locale", lang);
    // 	// 修改显示语言
    // 	this.$i18n.locale = lang;
    // 	// TODO 修改远程配置
    // }
    goBuildDetail(id) {
      this.$router.push({ path: "/projectDetail", query: { id: id } });
    },
  },
};
</script>

<style scoped="scoped">
.dropdown-menu {
  min-width: 6rem;
}
img {
  object-fit: cover;
}
.cont {
  background: #f2f2f2;
}

.jumbotron {
}

.search-box {
  position: absolute;
  width: 100%;
}

.search-box > .search-input-box {
  height: 4rem;
  background: #ffffff;
  border-radius: 0.625rem;
  margin: auto;
  z-index: 9;
  display: flex;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-right-22 {
  margin-right: 1.375rem !important;
}

.search-select {
  background: #ffffff !important;
  border: unset !important;
  border: unset !important;
  color: #000 !important;
  box-shadow: unset !important;
  height: 100% !important;
}

.key-words-box {
  margin: auto;
  padding: 1.5rem;
  font-size: 0.9375rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
}

.key-words {
  padding: 0 0.9375rem;
  cursor: pointer;
}

.search-input-info {
  border: none;
  outline: medium;
}

.search-box {
  position: absolute;
  width: 100%;
}

.text-align-left {
  text-align: left;
}

.video-box {
  padding: 1.375rem 0;
  display: flex;
  max-width: 100%;
}

.video {
  margin: auto;
  width: 24%;
}

.video:not(:first-child) {
  margin-left: 1%;
}

@media (min-width: 768px) {
  .jiant {
    display: none !important;
  }
  .search-box {
    bottom: 1rem;
  }

  .search-box > .search-input-box {
    width: 37.5rem;
  }

  .key-words-box {
    width: 37.5rem;
  }

  .search-input-info {
    width: 34.375rem;
  }

  .search-split-box {
    height: 100%;
    display: flex;
    margin: 0 0.5rem;
  }

  .search-split {
    height: 60%;
    margin: auto 0;
  }

  .search-icon {
    height: 1.875rem;
    width: 1.875rem;
    margin: auto 0;
    cursor: pointer;
  }

  .container-index {
    padding: 0 0.625rem;
  }

  .style {
    display: flex;
    justify-content: center;
    margin-top: 2.25rem;
    padding: 0 3rem;
  }
  .style-cont {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .style-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 0 3.47rem; */
    cursor: pointer;
  }

  .style-item img {
    width: 3.5rem;
    height: 3.5rem;
  }

  .style-item span {
    font-size: 1.17rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #221815;
    margin-top: 0.25rem;
  }
  .padding-bg-box {
    padding: 9.69rem 0 3.75rem 0;
  }
}

@media (min-width: 1200px) {
  .jiant {
    display: block !important;
  }
  .search-box {
    position: absolute;
    bottom: 5.625rem;
    width: 100%;
  }

  .search-box > .search-input-box {
    width: 52.5rem;
  }

  .key-words-box {
    width: 52.5rem;
  }

  .search-input-info {
    width: 34.375rem;
  }

  .container-index {
    padding: 0 6.1875rem;
  }
  .padding-bg-box {
    padding: 2.44rem 4.31rem 3.39rem 4.31rem;
  }
}
</style>
<style lang="scss" scoped="scoped">
/deep/ .carousel-control-prev-icon {
  background-image: url(/icon/banner-left@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-prev {
  background: unset;
  border: unset;
  width: unset !important;
  margin-left: 1.5rem;
}

/deep/ .carousel-control-next-icon {
  background-image: url(/icon/banner-right@2x.png) !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
}

/deep/ .carousel-control-next {
  background: unset;
  border: unset;
  width: unset !important;
  margin-right: 1.5rem;
}
@import "../../assets/scss/style.scss";
/*家的风格*/
@keyframes onefist {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes onefisr {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.09);
  }

  100% {
    transform: scale(1);
  }
}
@media (min-width: 1200px) {
  .big_event {
    padding: 0 20rem 4rem 20rem;
  }
}
@media (max-width: 1200px) {
  .big_event {
    padding: 0 1rem 4rem 1rem;
  }
  .a1 {
    padding: 0 1rem;
  }
}
.big_event {
  .big_title {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin: 4rem 0 3.06rem 0;
  }
  .big_name {
    padding-bottom: 0.44rem;
    border-bottom: 0.11rem solid #727171;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2.94rem;
    span {
      font-size: 0.93rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #221815;
    }
  }
  .big_cont {
    display: flex;
    justify-content: space-between;
    .big_left:hover > img {
      -moz-animation: onefist 1.5s;
      -o-animation: onefist 1.5s;
      -webkit-animation: onefist 1.5s;
      animation: onefist 1.5s;
    }
    .big_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        font-size: 0.81rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #221815;
      }
      img {
        width: 31.28rem;
        height: 25.83rem;
      }
    }
    .big_right {
      width: 34rem;
      p {
        font-size: 0.81rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #221815;
        line-height: 1.39rem;
        text-align: left;
        text-indent: 2em;
      }
    }
  }
}
.selection_box {
  padding-bottom: 4rem;
  .selection_title {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 2.22rem;
  }
  .selection {
    border-bottom: 0.0625rem solid #000000;
    .style_bot {
      border-bottom: 0.3125rem solid #f39800 !important;
    }
    .title_list {
      line-height: 3rem !important;
      cursor: pointer;
    }
    .title_rig {
      text-align: right;
      cursor: pointer;
    }
  }
  .choice-box {
    .choice-big:hover > .choice-big-img {
      -moz-animation: onefist 1.5s;
      -o-animation: onefist 1.5s;
      -webkit-animation: onefist 1.5s;
      animation: onefist 1.5s;
    }
    .choice-big {
      padding: 0.625rem;
      width: 65.9rem;
      height: 29rem !important;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      .choice-big-img {
        width: 100%;
        height: 100%;
        background: #ffffff;
        padding: 0 !important;
      }
      .choice_tit {
        width: 24.32rem;
        height: 5.16rem;
        background: #ffffff;
        opacity: 0.55;
        position: absolute;
        left: 1.83rem;
        bottom: 1.33rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .choice_name {
          font-size: 0.93rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #595757;
          margin: 1.68rem 0 1.11rem 1.79rem;
        }
        .choice_price {
          font-size: 0.93rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c13948;
          margin-bottom: 1.33rem;
        }
      }
    }
    .choice-min-p:hover > .choice-min-img {
      -moz-animation: onefist 1.5s;
      -o-animation: onefist 1.5s;
      -webkit-animation: onefist 1.5s;
      animation: onefist 1.5s;
    }
    .choice-min-p {
      padding: 0.625rem;
      // width: 33.99rem;
      // height: 22.38rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      .choice-min-img {
        width: 100%;
        height: 20.89rem;
        position: relative;
        overflow: hidden;
      }

      .choice_title {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        .choice_name {
          font-size: 0.93rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #595757;
          margin: 1.68rem 0 1.11rem 1.79rem;
        }
        .choice_price {
          font-size: 0.93rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c13948;
          margin-bottom: 1.33rem;
        }
      }
    }
  }
}

.a0 {
  margin-top: 2.25rem;
  position: relative;
  background-image: url("../../../public/icon/gohome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  .bb0 {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding-top: 4.89rem;
  }
  .b0 {
    width: 100%;
    // height: 39.31rem;
  }

  .aa1 {
    // position: absolute;
    // bottom: 3.75rem;
    // left: 4.31rem;
    display: flex;
    justify-content: center;
    // padding: 9.69rem 0 3.75rem 0;
    margin-left: 0;
    margin-right: 0;
    .b1:nth-child(4) {
      // margin-right: 0 !important;
      width: 100%;
    }

    .b1 {
      width: 27.19rem;
      padding: 0 0.44rem;
      // margin-right: 0.88rem;
      cursor: pointer;
      .c0-box:hover > .c0 {
        -moz-animation: onefist 1.5s;
        -o-animation: onefist 1.5s;
        -webkit-animation: onefist 1.5s;
        animation: onefist 1.5s;
      }
      .c0-box {
        background: #ffffff;
        position: relative;
        overflow: hidden;
        .c0 {
          width: 30.19rem;
          height: 19.63rem;
          max-width: 100%;
        }
        .c1 {
          display: flex;
          justify-content: space-between;
          margin-top: 1.56rem;
          padding-bottom: 1.69rem;

          .d0 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin-left: 1.5rem;

            .e0 {
              font-size: 1.43rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #221815;
            }

            .e1 {
              font-size: 0.91rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: #221815;
            }
          }

          .d1 {
            margin-right: 1.5rem;

            .e0 {
              width: 3.13rem;
              height: 3.13rem;
              margin-right: 0.31rem;
            }

            .e1 {
              width: 3.13rem;
              height: 3.13rem;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .a1 .e0 {
    // width: 14rem;
  }
}
@media (min-width: 1025px) {
  .a1 .e0 {
    width: 23rem;
  }
}

.a1 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .b0 {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    padding: 3.44rem 0 2.44rem 0;
    cursor: pointer;
  }

  .b1 {
    display: flex;
    align-items: center;
    cursor: pointer;

    .c0 {
      margin-right: 2.06rem;

      img {
        width: 1.81rem;
        height: 3.31rem;
      }
    }

    .c1 {
      display: flex;

      .d0 {
        .e0:hover > .f0 {
          -moz-animation: onefist 1.5s;
          -o-animation: onefist 1.5s;
          -webkit-animation: onefist 1.5s;
          animation: onefist 1.5s;
        }
        .e0 {
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          // width: 23rem;
          margin: 1rem 0;
          position: relative;
          overflow: hidden;
          .f0 {
            width: 100%;
            height: 17rem;
            // width: 100%;
          }

          .f1 {
            font-size: 1.04rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 0.69rem;
            margin-left: 1.69rem;
          }

          .f2 {
            font-size: 0.78rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #898989;
            margin: 0.56rem 0 0.56rem 1.69rem;
          }
        }
      }
    }

    .c2 {
      margin-left: 2.06rem;

      img {
        width: 1.81rem;
        height: 3.31rem;
      }
    }
  }
}

.container-index {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }
  .choice-title {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    padding-top: 3.89rem;
    padding-bottom: 2.74rem;
  }
  .more_box {
    width: 20.77rem;
    height: 2.18rem;
    background: #000000;
    font-size: 0.81rem;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    line-height: 2.18rem;
    margin: 3rem auto;
    cursor: pointer;
  }
  .choice-box {
    .choice-big:hover > .choice-big-img {
      -moz-animation: onefist 1.5s;
      -o-animation: onefist 1.5s;
      -webkit-animation: onefist 1.5s;
      animation: onefist 1.5s;
    }
    .choice-big {
      padding: 0.625rem;
      width: 65.9rem;
      height: 24rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .choice-big-img {
        width: 100%;
        height: 100%;
        // background: #ffffff;
        // padding: 0 18rem;
      }
    }
    .choice-min:hover > .choice-min-img {
      -moz-animation: onefist 1.5s;
      -o-animation: onefist 1.5s;
      -webkit-animation: onefist 1.5s;
      animation: onefist 1.5s;
    }
    .choice-min {
      padding: 0.625rem;
      width: 33.99rem;
      height: 24rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .choice-min-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .designer-box {
    max-width: 100%;

    .designer:not(:first-child) {
      border-left: solid 0.125rem rgba(200, 200, 200, 0);
    }

    .designer {
      width: 18rem;
      height: 23.75rem;
      background: #ffffff;
      text-align: center;
      align-items: center;
      display: flex;
      box-shadow: 0 0 0.3125rem rgba(200, 200, 200, 0.5);
      margin: 0 auto;

      .designer-item {
        text-align: center;
        width: 100%;

        .designer-avatar {
          width: 7.5rem;
          height: 7.5rem;
          border-radius: 50%;
        }

        .nick-name {
          padding-top: 1rem;
        }

        .design-num {
          padding-top: 0.4375rem;
        }

        .signature {
          padding-top: 0.75rem;
        }

        .follow {
          margin-top: 0.4375rem;
          border-radius: 1.25rem;
          border: unset;
          padding: 0.125rem 1.25rem;
          background: $bg-default-ns-color;
        }

        .follow-success {
          border: 0.0625rem solid $bg-default-s-color;
          color: $bg-default-s-color;
          background: unset;
        }

        .follow:active {
          background: $bg-default-s-color;
          color: #ffffff;
        }
      }
    }
  }

  .ehome-title-box {
    .label-box {
      display: flex;
      margin-left: auto;
      margin-right: 1.25rem;

      .label {
        padding: 0 0.875rem;
        height: 1.25rem;
        margin: auto 0;
        border-right: 0.125rem solid $bg-default-ns-color;
      }

      .label:not(:first-child) {
      }
    }
  }
}
.bg_img {
  padding: 5.25rem 0;
  position: relative;
  background-image: url("../../../public/icon/faut.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  .future-title {
    font-size: 1.27rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding: 0 0 3.0625rem 0;
  }
  .future_more {
    margin-top: 2.1875rem;
    cursor: pointer;
    span {
      display: inline-block;
      width: 18.75rem;
      height: 2rem;
      background: #ffffff;
      font-size: 0.6875rem;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #000000;
      line-height: 2rem;
    }
  }
  .future-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 7.5rem;
    margin-left: 0;
    margin-right: 0;
    .future-item {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      .decorative {
        position: relative;
        background-image: url("../../../public/icon/Decorative.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 34.75rem;
        .decorative-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 6.38rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .flower {
        position: relative;
        background-image: url("../../../public/icon/decoration.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 13.88rem;
        .flower-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 1.25rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .floral {
        position: relative;
        background-image: url("../../../public/icon/floral.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 20.88rem;
        .floral-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 4.5rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .lamps {
        position: relative;
        background-image: url("../../../public/icon/lamp.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 34.75rem;
        .lamps-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 13.56rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .carpet {
        position: relative;
        background-image: url("../../../public/icon/tapestry.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 20.88rem;
        .carpet-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 5.63rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .sculpture {
        position: relative;
        background-image: url("../../../public/icon/sculpture.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        width: 17.75rem;
        height: 13.88rem;
        .sculpture-icon {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 2.88rem 1.19rem 0 0;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .curtain {
        position: relative;
        background: #e6e6e6;
        width: 17.81rem;
        height: 17.37rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .curtain-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #85a92e;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .textile {
        position: relative;
        background: #d7d7d7;
        width: 17.81rem;
        height: 17.37rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .textile-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #85a92e;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .daily {
        position: relative;
        background: #d7d7d7;
        width: 17.81rem;
        height: 17.37rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .daily-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #85a92e;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
      .other {
        position: relative;
        background: #e6e6e6;
        width: 17.81rem;
        height: 17.37rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .other-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 1.43rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #85a92e;
          }
          .the-icon {
            width: 1.54rem;
            height: 1.54rem;
            background: #85a92e;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.69rem;
            img {
              width: 0.56rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>
